<template>
  <div>
    <!-- History Logs -->
    <div class="card card-custom gutter-b card-stretch">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder">การทายผลของคุณ</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">ตารางสรุปการทายผลของคุณ</span>
        </h3>
        <div class="card-toolbar">
          <router-link :to="predict" class="btn btn-success font-weight-bold font-size-h5 px-6 py-3">
            <i class="fa fa-plus mr-2"></i>ทายผลเพิ่ม
          </router-link>
        </div>
      </div>
      <div class="card-body pt-2">
        <div class="row">
          <div class="col-12 col-sm-6 col-xl-4 p-4">คู่การแข่งขัน</div>
          <div class="col-12 col-sm-6 col-xl-2 p-4 text-info">Date & Time</div>
          <div class="col-12 col-sm-6 col-xl-3 p-4 text-info">การลงคะแนน</div>
          <div class="col-12 col-sm-6 col-xl-3 p-4 text-info">Status</div>
        </div>
        <template v-for="(m, idx) in matches">
          <div v-bind:key="`c${idx}`" class="separator separator-solid"></div>
          <div v-bind:key="`r${idx}`" class="row align-items-center">
            <div class="col-12 col-sm-6 col-xl-4 p-4">
              <div class="h6">{{ m.name }}</div>
              <div class="text-dark-50">{{ m.meta }}</div>
            </div>
            <div class="col-12 col-sm-6 col-xl-2 p-4 text-info">
              <div class="h6 font-weight-bolder">{{ date(m) }}</div>
              <div>{{ time(m) }}</div>
            </div>
            <div class="col-12 col-xl-3 py-4">
              <b-row align-v="center" no-gutters>
                <b-col>
                  <strong>{{ m.choices.team }} <strong>{{ m.choices.name }}</strong></strong>
                  <div>{{ m.choices.pts }} คะแนน</div>
                </b-col>
              </b-row>
            </div>
            <div class="col-12 col-xl-3 py-4">
              <h6><b-badge :variant="statuscolor(m)">{{ status(m) }}</b-badge></h6>
            </div>
          </div>
        </template>

        <template v-if="notpredict">
          <div class="mt-5 card card-custom card-stretch gutter-b">
            <div class="card-body p-0">
              <div class="card-rounded p-8 bgi-no-repeat">
                <div class="text-center text-italic">No predicted matches</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: "game_info",
  components: {
  },
  data () {
    return {
      matches: []
    }
  },
  methods: {
    date (m) {
      return dayjs(m.time).format('DD/MM/YYYY')
    },
    time (m) {
      return dayjs(m.time).format('HH:mm')
    },
    timezone () {
      return dayjs().format('Z')
    },
    recvData() {
      this.axios.get(`/tournaments/${this.tour}/placepoint`).then(
        ({ data }) => {
          this.matches = data
        }
      )
    },
    status (m) {
      switch (m.status) {
        case 0: return 'ยังไม่เริ่มแข่ง'
        case 1: return 'เริ่มแข่งขัน'
        case 2: return 'ปิดการทายผล'
        case 3: return 'แข่งขันเสร็จ'
      }

      return ''
    },
    statuscolor (m) {
      switch (m.status) {
        case 0: return ''
        case 1: return 'info'
        case 2: return 'danger'
        case 3: return 'success'
      }

      return ''
    }
  },
  computed: {
    predict() {
      return `/games/${this.game}/tours/${this.tour}/predict`
    },
    tour() {
      return this.$route.params.tour
    },
    game() {
      return this.$route.params.game
    },
    notpredict() {
      return this.matches.length === 0
    }
  },
  mounted() {
    this.recvData()
  }
}
</script>

<style>

</style>